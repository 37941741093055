import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        languageState: false, // false=zh, true=en
    },
    mutations: {
        ChangeLanguageState(state, msg) {
          state.languageState = msg;
        },
    }
});


export default store;