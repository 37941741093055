<template>
  <div id="app">
    <v-header></v-header>
    <router-view />
  </div>
</template>
<script>
import VHeader from "@/views/navHeader.vue";
export default {
  name: "App",
  components: { VHeader },
  data() {
    return {};
  },
  created() {
    console.log("this", this.$store);
  },
  methods: {},
  mounted() {},
};
</script>
<style>
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#app::-webkit-scrollbar {
  display: none; /* 对于Chrome, Safari和Opera */
}
</style>
