<template>
  <div>
    <h5-header v-if="isMobile"></h5-header>
    <pc-header v-else></pc-header>
  </div>
</template>

<script>
import pcHeader from "@/components/pc/pc_header.vue";
import h5Header from "@/components/h5/h5_header.vue";
export default {
  name: "navHeader",
  data() {
    return {
      isMobile: false,
    };
  },
  components: { pcHeader, h5Header },
  created() {
    let flag = this._isMobile();
    if (flag != null) {
      console.log("移动端");
      this.isMobile = true;
    } else {
      console.log("pc端");
      this.isMobile = false;
    }
  },
  mounted() {},
  methods: {
    _isMobile: function () {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="less" scoped></style>
