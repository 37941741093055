<template>
  <div>
    <nav id="navBar" class="nav_bar">
      <img id="logo" :src="logSrc" />
      <ul id="menuBar" :class="{ 'title-span': navIndex == '2' }">
        <li
          id="index"
          class="navigation-link navigation-gray"
          :class="navIndex == '1' ? 'active' : ''"
          ui-sref="index"
          href="#/index"
          data-page="1"
        >
          {{ current_la.sy }}
        </li>
        <li
          class="navigation-link navigation-gray"
          :class="navIndex == '2' ? 'active' : ''"
          data-page="2"
        >
          {{ current_la.ywfw }}
        </li>
        <li
          class="navigation-link dropdown_menu marketing navigation-gray"
          data-page="3"
          :class="navIndex == '3' ? 'active' : ''"
        >
          {{ current_la.zxns }}
        </li>
        <li
          class="navigation-link dropdown_menu technology navigation-gray"
          :class="navIndex == '4' ? 'active' : ''"
          data-page="4"
        >
          {{ current_la.gywm }}
        </li>
      </ul>
      <div class="la_box" :class="{ 'title-span': navIndex == '2' }">
        <span @click="changeEn(0)" :class="{ spantitle: currentEn == 0 }"
          >中文</span
        >
        |
        <span @click="changeEn(1)" :class="{ spantitle: currentEn == 1 }"
          >English</span
        >
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logSrc: require("../../../static/img/logo.png"),
      currentEn: 0,
      navIndex: "1",
      current_la: {},
      CN_la: {
        sy: "首页",
        ywfw: "业务范围",
        zxns: "招贤纳士",
        gywm: "关于我们",
      },
      EN_la: {
        sy: "Home",
        ywfw: "Service",
        zxns: "Careers",
        gywm: "About us",
      },
    };
  },
  components: {},
  created() {
    this.changeHead();
  },
  mounted() {
    let _this = this;
    this.bus.$on("changeNav", function (index) {
      _this.changeNav(index);
    });
    document
      .getElementById("menuBar")
      .addEventListener("click", function (event) {
        var target = event.target;
        if (target.nodeName === "LI") {
          // 处理点击事件
          console.log(
            "List item clicked:",
            target.textContent,
            target.dataset.page
          );
          _this.navIndex = target.dataset.page;
          if (_this.navIndex == "3") {
            var nav = document.getElementsByClassName("nav_bar")[0];
            console.log("navDom", nav.style);
            nav.style.color = "#ffffff";
          }
          _this.bus.$emit("navChange", _this.navIndex);
        }
      });
  },
  watch: {
    navIndex(n, o) {
      console.log("appw", n);
      if (n == "2") {
        this.logSrc = require("../../../static/img/logo_01.png");
      } else {
        this.logSrc = require("../../../static/img/logo.png");
      }
    },
  },
  methods: {
    changeNav(index) {
      this.navIndex = index.toString();
    },
    changeEn: function (msg) {
      this.currentEn = msg;
      if (this.currentEn == 0) {
        this.$store.commit("ChangeLanguageState", false);
        this.current_la = this.CN_la;
      } else {
        this.$store.commit("ChangeLanguageState", true);
        this.current_la = this.EN_la;
      }
      this.changeHead();
    },
    changeHead: function () {
      if (this.$store.state.languageState == false) {
        this.current_la = this.CN_la;
      } else {
        this.current_la = this.EN_la;
      }
    },
  },
};
</script>

<style lang="less" scoped>
nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  min-width: 900px;
  height: 4.01vw;
  border-bottom: 0.05vw solid rgba(255, 255, 255, 0.1);

  font-family: MicrosoftYaHei;
  font-size: 0.94vw;
  color: #ffffff;
  display: flex;
  align-items: center;
}
nav #logo {
  margin-left: 18.75vw;
  width: 10vw;
  height: 3.02vw;
}
nav ul {
  margin: 0;
  list-style: none;
  padding-left: 7.5vw;
}
.title-span {
  color: #575656;
}
.active {
  font-family: MicrosoftYaHei-Bold;
  color: #0166ff;
  font-weight: bold;
}
.clearfix {
  float: right;
  position: relative;
}
.clearfix:before {
  content: "";
  display: table;
}
nav > ul > li {
  float: left;
  height: 4.01vw;
  padding: 0 2.66vw;
  font-weight: normal;
  line-height: 4.01vw;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.navigation-link {
  position: relative;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}
nav .navigation-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  border-bottom: solid 0.26vw #0166ff;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
.active,
.navigation-link:hover {
  background-color: rgba(52, 72, 255, 0.15);
}
nav .active::before {
  width: 100%;
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -ms-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
.navigation-link:hover:before {
  width: 100%;
}
.la_box {
  margin-left: 2.81vw;
  cursor: pointer;
}
.spantitle {
  color: #1880ff;
}
</style>
