<template>
  <nav id="navBar" class="nav_bar">
    <div class="nav_row nav_top">
      <img class="logo" id="logo" :src="logSrc" />
      <div
        class="language-switch"
        :class="{
          'title-span': selectedButton == 1,
        }"
      >
        <span
          @click="switchLanguage(0)"
          :class="{ selected: selectedLanguage === 0 }"
        >
          中文
        </span>
        |
        <span
          @click="switchLanguage(1)"
          :class="{ selected: selectedLanguage === 1 }"
        >
          English
        </span>
      </div>
    </div>
    <div class="nav_row nav_menu">
      <div
        class="nav_button"
        v-for="(button, index) in currentEn === 1 ? EN_la : CN_la"
        :key="index"
        @click="selectButton(index, 'click')"
        :class="{
          'title-span': selectedButton == 1,
          selected: selectedButton === index,
        }"
      >
        {{ button }}
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logSrc: require("../../../static/mobile/logo.png"),
      selectedLanguage: 0,
      selectedButton: 0, // 默认选中第一个按钮
      currentEn: 0,
      navIndex: "1",
      CN_la: ["首页", "业务范围", "招贤纳士", "关于我们"],
      EN_la: ["Home", "Service", "Careers", "About us"],
    };
  },
  watch: {
    selectedButton(n, o) {
      if (n == 1) {
        this.logSrc = require("../../../static/mobile/logo_02.png");
      } else {
        this.logSrc = require("../../../static/mobile/logo.png");
      }
    },
  },
  methods: {
    switchLanguage(language) {
      this.selectedLanguage = language;
      this.currentEn = language === 0 ? 0 : 1;
      this.$store.commit("ChangeLanguageState", language == 0 ? false : true);
    },
    selectButton(index, t) {
      this.selectedButton = index;
      if (t == "click") {
        this.bus.$emit("navChange", index);
      }
    },
    isShowNav(flag) {
      if (flag) {
        document.getElementById("navBar").style.display = "block";
      } else {
        document.getElementById("navBar").style.display = "none";
      }
    },
  },
  mounted() {
    let _this = this;
    this.bus.$on("changeNav", function (index) {
      _this.selectButton(index);
    });
    this.bus.$on("visibleNav", function (flag) {
      _this.isShowNav(flag);
    });
  },
};
</script>

<style lang="less" scoped>
nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 18.98vw;
  display: flex;
  flex-direction: column;
}

.nav_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.nav_top {
  height: 7.69vw;
  border-bottom: solid 0.09vw rgba(255, 255, 255, 0.1);
}
.nav_menu {
  height: 11.2vw;
}
.logo {
  margin-left: 6.57vw;
  width: 22.13vw;
  height: 6.57vw;
}

.language-switch {
  margin-right: 2vw;
  color: #fff;
  font-size: 3.7vw;
  cursor: pointer;
}
.nav_button {
  position: relative;
  cursor: pointer;
  color: #fff;
  font-family: MicrosoftYaHei;
  font-size: 3.63vw;
  padding: 0 5.66vw;

  height: 11.2vw;
  line-height: 11.2vw;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}
.nav_button::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  border-bottom: solid 0.26vw #0166ff;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
.title-span {
  color: #575656;
}
.nav_button.selected {
  font-family: MicrosoftYaHei-Bold;
  font-weight: bold;
  background-color: rgba(52, 72, 255, 0.15);
  color: #fff;
}
.title-span.selected,
.language-switch .selected {
  color: #0166ff;
}

.selected::before {
  width: 100%;
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -ms-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
</style>
