import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

function isMobile() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  if (flag != null) {
    return true;
  } else {
    return false;
  }
}

var routes;
if (isMobile()) {
  routes = new Router({
    mode: "history",
    routes: [
      {
        path: "/",
        name: "index",
        component: (resolve) => require(["@/views/h5_home"], resolve),
      },
    ],
  });
} else {
  routes = new Router({
    mode: "history",
    routes: [
      {
        path: "/",
        name: "index",
        component: (resolve) => require(["@/views/pc_home"], resolve),
      },
    ],
  });
}

export default routes;
